/* All css  */
.App {
  text-align: center;
}
.showMenuMobile {
  @media (max-width: 815px) {
    width: 50vw;
  }
  @media (max-width: 450px) {
    width: 100vw;
  }
}
.admindash:hover {
  cursor: pointer;
}
a {
  cursor: pointer;
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}
.custom-cursor-none a {
  cursor: default; /* Removes the cursor pointer */
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}
/* #layoutSidenav_nav {
  width: 220px;
} */
@media (max-width: 450px) {
  /* #layoutSidenav_nav {
    width: 380px;
  } */
}
@media (max-width: 800px) {
  /* #layoutSidenav_nav {
    width: 380px;
  } */
}
.App-link {
  color: #61dafb;
}
.qouteInputs {
  /* border: none; */
  width: 137px;
  color: #867070;
}
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
td.MuiTableCell-root.MuiTableCell-body.MuiTableCell-alignLeft.MuiTableCell-sizeMedium.css-1ex1afd-MuiTableCell-root {
  border-bottom: none;
  padding: 4px;
}
@media (min-width: 300px) and (max-width: 320px) {
  .card.shadow-lg.border-0.rounded-lg.mt-5 {
    left: -6%;
    width: 86%;
  }
}
@media (min-width: 321px) and (max-width: 340px) {
  .card.shadow-lg.border-0.rounded-lg.mt-5 {
    left: -5%;
    width: 86%;
  }
}
@media (min-width: 343px) and (max-width: 395px) {
  .card.shadow-lg.border-0.rounded-lg.mt-5 {
    left: -4%;
  }
}

@media (min-width: 300px) and (max-width: 320px) {
  .card.shadow-lg.border-0.rounded-lg.mt-5 {
    left: -6%;
    width: 86%;
  }
}
@media (min-width: 300px) and (max-width: 500px) {
  #viewquotebtn {
    width: 110px;
    margin-left: 10px;
  }

  #deletequotebtn {
    width: 110px;
    margin-left: 40px;
  }
  #editquotebtn {
    width: 110px;
  }

  #deletequotebtnmat {
    width: 120px;
    margin-left: 50px;
  }
  #viewquotebtnmat {
    width: 120px;

    margin-left: 10px;
  }
  div#layoutSidenav {
    left: -23%;
    /* overflow: hidden; */
    position: relative;
    width: 123%;
  }
  div#accordionSidenav {
    position: relative;
    left: 33%;
  }
  nav.sidenav.shadow-right.sidenav-light {
    width: 67% !important ;
  }
  .sidenav-footer {
    position: relative;
    left: 34%;
  }

  .container-xl.px-4.mt-4 {
    width: 89%;
    position: relative;
    left: 8%;
  }
  .page-header .page-header-content {
    padding-bottom: 3rem;
    left: 17%;
    position: relative;
  }
  .card.lift {
    text-decoration: none;
    color: inherit;
    left: 15.5%;
    width: 91%;
  }
  .card {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid rgba(33, 40, 50, 0.125);
    border-radius: 0.35rem;
    left: 2%;
  }
  .sidenav .sidenav-menu .nav .nav-link {
    left: -8%;
  }
  .sidenav-toggled #layoutSidenav #layoutSidenav_content:before {
    left: 23px !important;
  }
  .row.align-items-center.justify-content-between.pt-3 {
    left: 21% !important;
    position: relative;
  }
  header.page-header.page-header-dark.bg-gradient-primary-to-secondary.pb-10 {
    left: 40%;
    position: relative;
  }
  .page-header .page-header-content {
    left: -3%;
  }
  html {
    overflow: hidden;
  }
  .Dashboardcard {
    left: 4%;
    position: relative;
  }
  #solid {
    left: 12%;
    position: relative;
    width: 91% !important;
  }
  #solid2 {
    left: 12%;
    position: relative;
    width: 91% !important;
  }
  #solid3 {
    left: 12%;
    position: relative;
    width: 91% !important;
  }
  .container-xl.px-4.mt-n10 {
    left: 8%;
    position: relative;
  }
  .card.lift {
    left: 7%;
  }
  #dashMobile {
    left: 19%;
  }
  #dashMobile2 {
    left: 19%;
  }
  #dashMobile3 {
    left: 19%;
  }
  #AdminDashMob {
    left: 15%;
  }

  .col-md-6.small {
    left: 18%;
    position: relative;
  }

  #RegisterMob {
    width: 102%;
    left: -1%;
    position: relative;
  }
  #RegisterMob2 {
    width: 112%;
  }
  #RecMob2 {
    width: 112%;
  }
  #verfMob {
    width: 112%;
  }
  #newPassMob {
    width: 112%;
  }
  #Adminblue {
    left: 0%;
  }
  #Adminyellow {
    left: 0%;
  }
  #AdminGreen {
    left: 0%;
  }
  #profileMob {
    left: 20%;
    position: relative;
  }
  #SecurityMOb {
    left: -18%;
    position: relative;
  }
  #SecurityMOb2 {
    left: -1%;
    position: relative;
  }
  .JobSMob {
    width: 24%;
  }
  .card-body {
    margin: 10px;
  }
}
@media (min-width: 300px) and (max-width: 1000px) {
  #sidenavAccordion {
    width: 100%;
  }

  header.page-header.page-header-compact.page-header-light.border-bottom.bg-white.mb-4 {
    position: relative;
    left: 4%;
    width: 122%;
  }
}
@media (min-width: 768px) and (max-width: 1024px) {
  div#layoutSidenav {
    left: -7%;
  }
  #layoutSidenav #layoutSidenav_content {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-width: 0;
    flex-grow: 1;
    min-height: calc(100vh - 3.625rem);
    margin-left: -13rem !important;
  }
  html {
    overflow: hidden;
  }
  button#deletequotebtnmat {
    margin-left: 0px !important;
  }

  .SavedWidth {
    width: 151%;
  }

  /* button#viewquotebtnmat {
    margin-right: 0px;
  } */
}
@media (min-width: 768px) and (max-width: 1600px) {
  .page-header-subtitle br {
    display: none; /* Hide <br> tags */
  }
}
@media (min-width: 768px) and (max-width: 860px) {
  button#viewquotebtnmat {
    margin-right: 1px !important;
    /* width: 55%; */
  }
}
@media (min-width: 501px) and (max-width: 768px) {
  button#viewquotebtnmat {
    margin-right: 1px !important;
    width: 55%;
  }
}
